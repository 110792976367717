import { useMemo } from 'react'
import { BLOCKS, type Text } from '@contentful/rich-text-types'
import TabGroup, { TabItem } from '@/components/tab-group'
import Code from './code/code'
import { isPrint } from '@/utils/print'
import Callout from './callout'
import RichText from './rich-text'
import Image from './image.tsx'

export interface Props {
  groupId: string
  body: any
}

export default function Tabs({ groupId, content }: Props): JSX.Element {
  const tabs = useMemo(() => {
    const data = []
    content.forEach((node) => {
      if (node.nodeType === BLOCKS.HEADING_1) {
        data.push({ heading: node.content as Text[], content: [] })
      } else data[data.length - 1].content.push(node)
    })
    return data
  }, [])

  const renderTabContent = (tab) => {
    return tab.content.map((node, i) => {
      if (node.nodeType === BLOCKS.EMBEDDED_ENTRY) {
        if (node.data?.target?.sys?.contentType?.sys?.id === 'componentCode') {
          return <Code key={`tab-content-${i}`} {...node.data.target.fields} />
        }
        if (node.data?.target?.sys?.contentType?.sys?.id === 'resourceImage') {
          return <Image key={`tab-content-${i}`} {...node.data.target.fields} />
        }
        if (node.data?.target?.sys?.contentType?.sys?.id === 'componentCallout') {
          return (
            <Callout key={`tab-content-${i}`} {...node.data.target.fields} theme="light">
              <RichText body={node.data.target.fields.body} />
            </Callout>
          )
        }
      }
      return <RichText key={`tab-content-${i}`} body={node} />
    })
  }

  if (isPrint()) {
    return (
      <div>
        {tabs.map((tab, index) => {
          return (
            <div key={index}>
              <h2>⇒{tab.heading.map((heading) => heading.value)}</h2>
              {renderTabContent(tab)}
            </div>
          )
        })}
      </div>
    )
  } else
    return (
      <TabGroup>
        {tabs.map((tab, index) => {
          const tabId = tab.heading.length < 1 ? index + 1 : tab.heading[0].value.replace(/\s+/g, '-').toLowerCase()

          return (
            <TabItem
              tabId={`tab-${tabId}-${groupId}`}
              key={index}
              buttonContent={tab.heading.map((heading) => {
                if (heading.nodeType === 'embedded-entry-inline') {
                  return <RichText body={heading} />
                }
                return heading.value
              })}
            >
              {renderTabContent(tab)}
            </TabItem>
          )
        })}
      </TabGroup>
    )
}
